import { Code } from '@chakra-ui/react';
import {
  Alert,
  AlertDescription,
  Button,
  ButtonLink,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  faCircleExclamation,
  faCircleStar,
  Header,
  Icon,
  Panel,
  Paragraph,
} from 'packages/elements';
import { Dispatch, SetStateAction } from 'react';

import PrismHighlighter from '@/components/PrismHighlighter';
import { PartialRegistryPackage } from '@/redesign/types';
import { MARKETING_TIER_PRICING_URL } from '@/utils/constants/urls';
import { getCodeBlock } from '@/utils/helpers/packages';

export const InstallModal: React.FC<{
  data: PartialRegistryPackage;
  userCanAccessPackage: boolean;
  isInstallModalVisible: boolean;
  setIsInstallModalVisible: Dispatch<SetStateAction<boolean>>;
}> = ({ data, userCanAccessPackage, isInstallModalVisible, setIsInstallModalVisible }) => {
  const isInFamilyProvider = !!data.familyRepositoryId && data.familyRepositoryId !== data.repositoryId;
  const familyRepositoryName = data.familyRepositoryId ? data.familyRepositoryId.split('/')[1] : '';

  // TODO: Update styling of codeblock to use dark theme

  if (!userCanAccessPackage) {
    return (
      <Dialog
        open={isInstallModalVisible}
        onOpenChange={() => {
          setIsInstallModalVisible(!isInstallModalVisible);
        }}
      >
        <DialogContent className="max-w-[500px] p-8">
          <DialogHeader>
            <div className="flex flex-row gap-4 items-start">
              <Icon fontSize={30} icon={faCircleStar} className="-mt-1.5" />
              <Header type="h5" className="mb-3">
                Get unlimited access to official packages with an upgraded Upbound account
              </Header>
            </div>
          </DialogHeader>
          <DialogFooter>
            <ButtonLink btnType="Primary" href={MARKETING_TIER_PRICING_URL}>
              Upgrade
            </ButtonLink>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={isInstallModalVisible}
      onOpenChange={() => {
        setIsInstallModalVisible(!isInstallModalVisible);
      }}
    >
      <DialogContent className="max-w-[800px] p-8 w-auto">
        <DialogHeader>
          <Header type="h5" className="mb-3">
            Install Instructions
          </Header>
          <DialogDescription className="space-y-3">
            <div className="flex flex-col gap-8">
              <Paragraph>
                The following manifest can be used to install this package in your control plane. After you have
                connected to your control plane run <Code padding="0px 5px 0px 5px">kubectl apply -f</Code> after
                copying the following into a local file.
              </Paragraph>
              <Panel className="p-0">
                <div className="border-b border-solid border-neutral-100 p-5">
                  <Header type="h5">{data.name} manifest</Header>
                </div>

                <div className="p-4">
                  <PrismHighlighter
                    language="yaml"
                    plugins={['line-numbers', 'copy-to-clipboard']}
                    copyIconProps={{ className: 'copy-manifest-button' }}
                  >
                    {getCodeBlock(data)}
                  </PrismHighlighter>
                </div>
              </Panel>

              {isInFamilyProvider && (
                <Alert className="flex border-solid border-neutral-100 w-auto" variant="info">
                  <div className="flex shrink-0 grow-0 items-center justify-items-center pr-4">
                    <Icon icon={faCircleExclamation} className=" !text-neutral-800" />
                  </div>
                  <div>
                    <AlertDescription>
                      <Paragraph>
                        This package has a dependency on {familyRepositoryName} which will be automatically installed.
                      </Paragraph>
                    </AlertDescription>
                  </div>
                </Alert>
              )}
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            btnType="Secondary"
            onClick={() => {
              setIsInstallModalVisible(!isInstallModalVisible);
            }}
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

import { Badge, faCircleStar, Icon } from 'packages/elements';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export const OfficialBadge: React.FC = () => {
  return (
    <Badge variant="branded" className="border-transparent font-normal">
      <Icon className="mr-1" icon={faCircleStar} />
      Official
    </Badge>
  );
};

export const ClearBadge: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Badge
      variant="static"
      className="border border-neutral-200 bg-neutral-0 py-0 px-1 rounded-sm font-normal max-w-[100px] inline-block truncate"
    >
      {children}
    </Badge>
  );
};

export const GenericBadge: React.FC<{ className?: string; title?: string } & PropsWithChildren> = ({
  className,
  title,
  children,
}) => {
  const badgeClass = twMerge(
    'border-transparent py-0 px-1 rounded-sm font-normal max-w-[60px] lg:max-w-[100px] inline-block truncate',
    className,
  );
  return (
    <Badge variant="static" className={badgeClass} title={title}>
      {children}
    </Badge>
  );
};
